// 注册element-ui组建库
import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// 注册Swiper
import VueAwesomeSwiper from 'vue-awesome-swiper'
// import style (>= Swiper 6.x)
import 'swiper/swiper-bundle.css'


Vue.use(ElementUI);
Vue.use(VueAwesomeSwiper, /* { default options with global component } */)
