export default [
    {
        path: '/home',
        component: () => import('@/views/home'),
        name: 'home',
    },
    {
        path: '/cloud',
        component: () => window.location.href = "https://cloud.casicloud.com/",//import('@/views/cloud/cloud'),
        name: 'cloud',
    },
    {
        path: '/park',
        component: () => import('@/views/park/park'),
        name: 'park',
    },
    {
        path: '/digital',
        component: () => import('@/views/digital/digital'),
        name: 'digital',
    },
    {
        path: '/about',
        component: () => import('@/views/about/about'),
        name: 'about',
    },
];
