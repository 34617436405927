import Vue from 'vue'
import VueRouter from 'vue-router'
import modules from './modules/Cloud.js';
/* Layout */
import Layout from '@/views/Index';
Vue.use(VueRouter)

const routeModules = modules.modules || modules;
const subRoutes = [];
for (const key in routeModules) {
  if (Object.prototype.hasOwnProperty.call(routeModules, key)) {
    if (Array.isArray(routeModules[key])) {
      subRoutes.push(...routeModules[key]);
    } else {
      subRoutes.push(routeModules[key]);
    }
  }
}

const routes = [
  {
    path: '/',
    component: Layout,
    name: 'RootLayout',
    redirect: '/home',
    children: subRoutes,
  },
]

const createRouter = () =>
    new VueRouter({
      // mode: 'history', // require service support
      scrollBehavior: () => ({ y: 0 }),
      routes
    });

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}

const router = createRouter();

export default router
