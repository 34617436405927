<template>
  <div class="main" @click="closeIfOutside">
    <!-- 公告 -->
    <div class="statementImg" v-if="showStatement">
      <img class="content" src="@/assets/statement.jpg" alt="" />
      <img class="close" src="@/assets/close.jpg" alt="" @click="close"/>

    </div>
    <!--  el布局容器  -->
    <el-container>
      <!--  头部容器  -->
      <el-header class="header">
        <el-row type="flex" justify="center">
          <!--  头部LOGO  -->
          <el-col :span="2" :offset="4">
            <div class="logoBox">
              <img src="@/assets/logo.png" alt @click="routerPush" />
            </div>
          </el-col>

          <!--  头部菜单  -->
          <el-col :span="7" :offset="1">
            <el-menu
              :default-active="activeIndex"
              class="el-menu-demo"
              mode="horizontal"
              @select="onMenuSelect"
            >
              <el-submenu
                v-for="(tab, idx) in tabs"
                :key="idx"
                :index="idx.toString()"
              >
                <template slot="title">{{ tab.title }}</template>
                <el-menu-item
                  v-for="(item, subIdx) in tab.data"
                  :index="subIdx.toString()"
                  :key="subIdx"
                >
                  {{ item.title }}
                </el-menu-item>
              </el-submenu>
            </el-menu>
          </el-col>

          <!--  头部咨询  -->
          <el-col :span="4" :offset="6">
            <div class="hotline">
              <span>售前咨询</span> <span>400-183-1991</span>
            </div>
          </el-col>
        </el-row>
      </el-header>

      <!--  头部大图  -->
      <el-main class="main">
        <el-row>
          <el-col :span="24">
            <div class="bannerBox">
              <img src="@/assets/banner.png" alt="" />
            </div>
          </el-col>
        </el-row>
      </el-main>

      <!--   中部主题内容   -->
      <div class="login-wrapper">
        <transition name="fade" mode="out-in">
          <router-view></router-view>
        </transition>
      </div>

      <!--   尾部内容   -->
      <el-footer height="488px" class="footer">
        <el-row type="flex" justify="center" class="footer-row">
          <el-col :span="4" class="footer-col">
            <div class="QR-code"></div>
          </el-col>
          <el-col :span="1" class="footer-col">
            <div class="dividing-row-line"></div>
          </el-col>
          <el-col :span="4.5" class="footer-col-div">
            <div
              class="footer-col-flex"
              style="margin-bottom: 25px"
              v-for="(item, idx) in informationList"
              :key="idx"
            >
              <div>
                <img :src="item.img" alt="" />
              </div>
              <div
                style="margin-left: 30px; font-family: SourceHanSansCN-Normal"
              >
                {{ item.title }}
              </div>
            </div>
          </el-col>
          <el-col :span="1" class="footer-col">
            <div class="dividing-row-line"></div>
          </el-col>
          <el-col :span="3" class="footer-cols">
            <div class="ul-list">
              <h2>目录</h2>
              <ul>
                <li>
                  <a @click="projectClick">产品与解决方案</a>
                </li>
                <li>
                  <a @click="partnerClick">合作伙伴计划</a>
                </li>
                <li>
                  <a @click="caseClick">行业案例</a>
                </li>
                <li><a @click="aboutClick">关于我们</a></li>
              </ul>
            </div>
          </el-col>
        </el-row>

        <el-row type="flex" justify="center">
          <el-col :span="16">
            <div class="dividing-line"></div>
          </el-col>
        </el-row>

        <el-row type="flex" justify="center">
          <el-col :span="16">
            <div style="text-align: center; margin-top: 40px">
              Copyright © 2015-2021 北京高升数据系统有限公司 All rights
              reserved.
            </div>
            <div style="text-align: center">
              <el-link
                style="color: #fff"
                :underline="false"
                href="https://beian.miit.gov.cn"
                target="_blank"
                >京ICP备2021000549号</el-link
              >
            </div>
          </el-col>
        </el-row>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import bus from "@/utils/bus";
export default {
  name: "login",
  data() {
    return {
      showStatement: true,
      activeIndex: "home",
      informationList: [
        {
          img: require("@/assets/logo反白.png"),
          title: "北京高升数据系统有限公司",
        },
        {
          img: require("@/assets/电话.png"),
          title: "400-183-1991",
        },
        {
          img: require("@/assets/邮件.png"),
          title: "data@gosun.com",
        },
        {
          img: require("@/assets/定位.png"),
          title: "天津市空港经济区环河南路99号",
        },
      ],
      tabs: [
        {
          title: "产品与解决方案",
          data: [
            {
              title: "云产品",
              router_name: "cloud",
            },
            {
              title: "大数据可视化平台",
              router_name: "digital",
            },
            {
              title: "园区解决方案",
              router_name: "park",
            },
          ],
        },
        {
          title: "行业案例",
          data: [
            {
              title: "混合云平台 - 上海市某科技园混合云平台",
              router_name: "case",
            },
            {
              title: "园区云平台 - 某省产业园区云平台",
              router_name: "case",
            },
            {
              title: "数字城市 - 数字智慧管控平台",
              router_name: "case",
            },
          ],
        },
        {
          title: "关于我们",
          data: [
            {
              title: "公司介绍",
              router_name: "about",
              router_id: "introduce",
            },
            {
              title: "发展历程",
              router_name: "about",
              router_id: "experience",
            },
            {
              title: "核心优势",
              router_name: "about",
              router_id: "advantage",
            },
            {
              title: "荣誉资质",
              router_name: "about",
              router_id: "honor",
            },
            {
              title: "战略伙伴",
              router_name: "about",
              router_id: "partner",
            },
          ],
        },
      ],
    };
  },
  methods: {
    projectClick() {
      if (this.$route.path === "/home") {
        bus.$emit("on-jump-description");
      } else {
        this.$router.push({ path: "/home" });
        setTimeout(() => {
          bus.$emit("on-jump-description");
        }, 500);
      }
    },
    partnerClick() {
      if (this.$route.path === "/home") {
        bus.$emit("message", 0);
      } else {
        this.$router.push({ path: "/home" });
        setTimeout(() => {
          bus.$emit("message", 0);
        }, 500);
      }
    },
    caseClick() {
      if (this.$route.path === "/home") {
        bus.$emit("on-jump-case-module", "0");
      } else {
        this.$router.push({ path: "/home" });
        setTimeout(() => {
          bus.$emit("on-jump-case-module", "0");
        }, 500);
      }
    },
    aboutClick() {
      this.$router.push("/about");
    },
    routerPush() {
      this.$router.push("/home");
    },
    onMenuSelect(index, indexPath) {
      let section = parseInt(indexPath[0]);
      let row = parseInt(indexPath[1]);
      let router_name = this.tabs[section].data[row].router_name;
      console.log(section, row, router_name);
      switch (router_name) {
        case "about":
          {
            let router_id = this.tabs[section].data[row].router_id;

            if (this.$route.path === "/about") {
              bus.$emit("jump", router_id);
            } else {
              this.$router.push({ path: "/about" });
              setTimeout(() => {
                bus.$emit("jump", router_id);
              }, 500);
            }
          }
          break;
        case "case":
          {
            if (this.$route.path === "/home") {
              bus.$emit("on-jump-case-module", row);
            } else {
              this.$router.push({ path: "/home" });
              setTimeout(() => {
                bus.$emit("on-jump-case-module", row);
              }, 500);
            }
          }
          break;
        case "digital":
          {
            if (this.$route.path === "/digital") {
              bus.$emit("digital-message");
            } else {
              this.$router.push({ path: "/digital" });
              setTimeout(() => {
                bus.$emit("digital-message");
              }, 500);
            }
          }
          break;
        case "park":
          {
            if (this.$route.path === "/park") {
              bus.$emit("park-message");
            } else {
              this.$router.push({ path: "/park" });
              setTimeout(() => {
                bus.$emit("park-message");
              }, 500);
            }
          }
          break;
        default:
          {
            this.$router.push("/" + router_name);
          }
          break;
      }
    },
    // 关闭公告
    closeIfOutside(event) {
      if (!event.target.closest('.statementImg')) {
        this.showStatement = false;
      }
    },
    close(){
      this.showStatement = false;
    }
  },
};
</script>
<style>
.el-menu--horizontal > .el-submenu:focus .el-submenu__title,
.el-menu--horizontal > .el-submenu:hover .el-submenu__title {
  font-family: SourceHanSansCN-Bold !important;
}
</style>
<style scoped lang="scss">
$font-size-mini: 12px;
$font-size-small: 14px;
$font-size-default: 16px;
$icon: support-hotline;
//.login-wrapper {
//  display: flex;
//  width: 100%;
//  min-height: 560px;
//  height: 100%;
//  align-items: center;
//  justify-content: center;
//  overflow: hidden;
//}
// 分割线
.dividing-row-line {
  display: inline-block;
  width: 2px;
  height: 120px;
  background: #ffffff;
  border-radius: 1px;
  line-height: 301px;
}
.main {
  max-width: 1920px;
  margin: 0 auto;
  //  头部菜单
  .header {
    width: 100%;
    height: 40px;
    background: #fff;
    padding: 0;
    // LOGO图片
    .logoBox {
      height: 61px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    // 头部导航
    .el-menu-demo {
      min-width: 565px;
    }
    // 右侧服务电话
    .hotline {
      height: 100%;
      width: 100%;
      color: #fff;
      font-family: SourceHanSansCN;
      line-height: 60px;
      text-align: center;
      background-image: url("~@/assets/#{$icon}.png");
      float: right;
    }
    //  导航样式
    >>> .el-submenu__title:focus,
    .el-submenu__title:hover {
      font-weight: bold;
      color: #c1c1c1;
    }
  }
  .statementImg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1000;
    width: fit-content;
    margin: 0 auto;
    .content {
      width: auto;
      height: 100vh;
    }
    .close{
      width: 30px;
      height: 30px;
      position: absolute;
      top: 0;
      right: 0;
      cursor: pointer;
    }
  }

  // main主体部分
  .main {
    padding: 0;

    // banner图
    .bannerBox {
      img {
        width: 100%;
        height: 720px;
      }
    }
  }

  // 尾部公司信息
  .footer {
    width: 100%;
    background: #1c1c1c;
    padding: 0;
    color: #ffffff;
    span {
      font-family: SourceHanSansCN-Normal;
      font-size: 16px;
      color: #ffffff;
    }
    .footer-row {
      margin-top: 70px;
      .QR-code {
        width: 196px;
        height: 196px;
        border: 2px solid #d8a500;
        background: url("~@/assets/QR-code.png");
      }
      .footer-col {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .footer-col-div {
        display: flex;
        flex-flow: column wrap;
        justify-content: space-around;
        align-items: flex-start;
        margin-top: 25px;
        .footer-col-flex {
          margin-top: 10px;
          display: flex;
          flex-flow: row nowrap;
          align-items: center;
        }
        span {
          line-height: 30px;
          display: inline-block;
          margin-left: -130px;
        }
      }
    }
    .footer-cols {
      display: flex;
      align-items: center;
      justify-content: center;
      .ul-list {
        padding: 20px;
        h2 {
          font-family: SourceHanSansCN-Bold;
        }
        li {
          list-style-type: disc;
          margin-top: 20px;
          font-family: SourceHanSansCN-Normal;
        }
        a {
          text-decoration: none;
          color: #ffffff;
        }
      }
    }

    // 分割线
    .dividing-line {
      height: 2px;
      background: #ffffff;
      border-radius: 1px;
      margin-top: 20px;
    }
  }
}
</style>
<style scoped>
.el-menu-item {
  font-family: SourceHanSansCN-Normal !important;
  color: #808080 !important;
  height: 60px !important;
  line-height: 60px !important;
  padding-left: 20px !important;
}
.el-menu-item:hover {
  background-image: linear-gradient(159deg, #8fc6fe 0%, #2965ed 100%);
  font-family: SourceHanSansCN-Bold !important;
  color: #ffffff !important;
}
.el-menu-item.is-active {
  background-image: linear-gradient(159deg, #8fc6fe 0%, #2965ed 100%);
  font-family: SourceHanSansCN-Bold !important;
  color: #ffffff !important;
}
</style>
